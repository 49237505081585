export class DomEventListenerManager{
  constructor(){
    this.listeners = [];
  }
  
  registerListener(node,eventName,handler,...params){
    this.listeners.push({node,eventName,handler,params});
    node.addEventListener(eventName,handler,...params);
  }
  
  removeListener(node,eventName,handler,...params){
    let filteredListeners = [];
    const indexs = [];
    const args = arguments;
    for(const index in this.listeners){
      const listener = this.listeners[index];
      if(
        (node ? listener.node === node : true)
        && (eventName || args.length >= 2 ? eventName === listener.eventName : true)
        && (handler || args.length >= 3 ? handler === listener.handler : true)
        && ((params && params.length) || args.length >= 4 ? params === listener.params : true)
      ){
        indexs.push(index);
        filteredListeners.push(this.listeners[index]);
      }
    }
    
    this.listeners = this.listeners.filter((item,index)=>{
      return indexs.indexOf(index) === -1;
    })
    
    while(filteredListeners.length){
      const listener = filteredListeners.pop();
      const {node,eventName,handler} = listener;
      const params = listener.params ? listener.params : [];
      if(node){
        node.removeEventListener(eventName,handler,...params);
      }
    }
  }
  
  destroy(){
    this.removeListener();
    
    for (const field in this) {
      if (Object.prototype.hasOwnProperty.call(this, field)) {
        delete this[field];
      }
    }
    
    Object.setPrototypeOf(this, null);
  }
}


export function getSpecificParentElement(innerNode,judgeFn){
  /**
   * @type HTMLElement
   */
  let currentElement = innerNode;
  while(currentElement && !judgeFn(currentElement)){
    currentElement = currentElement.parentElement;
  }
  return currentElement;
}

/**
 * 获取某元素所在的滚动窗口
 * @param innerNode
 * @returns {HTMLElement}
 */
export function getScrollParentElement(innerNode){
  return getSpecificParentElement(innerNode,(element) => {
    return ['scroll','auto'].includes(getComputedStyle(element).overflowY)
  })
}

export function isMobile(){
  return navigator.userAgent.match(/(iPhone|iPod|Android|ios)/i);
}
