
import { Options, Vue } from 'vue-class-component';
@Options<MetaLogo>({
  components: {
  },
  props:{
    clickable:{
      required:false,
      type: Boolean,
    }
  }
})
export default class MetaLogo extends Vue {
  clickable?:boolean;
}
