import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4437668a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "full-screen-image-swiper" }
const _hoisted_2 = { class: "fsis-swiper-area" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_svg_icon = _resolveComponent("svg-icon")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "fsis-close",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close')))
      }, [
        _createVNode(_component_svg_icon, { name: "close" })
      ]),
      _createVNode(_component_swiper, {
        onSwiper: _ctx.onSwiper,
        navigation: true,
        modules: _ctx.modules,
        class: "fsis-swiper"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (img, index) => {
            return (_openBlock(), _createBlock(_component_swiper_slide, { key: index }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  class: "fsis-image",
                  src: img
                }, null, 8, _hoisted_3)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["onSwiper", "modules"])
    ])
  ]))
}