import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4ad5fe74"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "react-scroll-image",
    style: _normalizeStyle({width: _ctx.width,height: _ctx.height})
  }, [
    _createElementVNode("img", {
      class: "si-img",
      ref: "image",
      style: _normalizeStyle({
      width:`calc(100% + ${_ctx.widthOffset}px)`,
      height: _ctx.height
    }),
      src: _ctx.src
    }, null, 12, _hoisted_1)
  ], 4))
}