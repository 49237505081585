import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/meta-logo.webp'


export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("img", {
    class: _normalizeClass(["meta-logo", {clickable: _ctx.clickable}]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.clickable && _ctx.$router.push({name:'Home'}))),
    src: _imports_0
  }, null, 2))
}