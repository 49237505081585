import {createRouter, createWebHashHistory, RouteRecordRaw} from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        alias: ['/home', '/index'],
    },
    {
        path: '/about',
        name: 'About',
        component:() => import(/* webpackChunkName: "about" */ '../views/About.vue'),
    },
    {
        path: '/production',
        name: 'Production',
        component:() => import( '../views/Production.vue'),
        children:[
            {
                path: '/metasoai',
                name: 'Metasoai',
                component:() => import(/* webpackChunkName: "about" */ '../views/productions/Metasoai.vue'),
            },
            {
                path: 'Interpret',
                name: 'Interpret',
                component:() => import( '../views/productions/Interpret.vue'),
            },
            {
                path: 'Xiezuocat',
                name: 'Xiezuocat',
                component: () => import( '../views/productions/Xiezuocat.vue'),
            },
            {
                path: 'MetaGo',
                name: 'MetaGo',
                component: () => import( '../views/productions/MetaGo.vue'),
            },
            {
                path: 'MetaLaw',
                name: 'MetaLaw',
                component: () => import( '../views/productions/MetaLaw.vue'),
            }
        ]


    },
]

const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router
