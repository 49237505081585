
import { Options, Vue } from 'vue-class-component';
import SwipeIntroduce from "@/components/SwipeIntroduce.vue";
import {DomEventListenerManager,isMobile} from "@/utils";
import ReactScrollImage from "@/components/ReactScrollImage.vue";
import Footer from "@/components/Footer.vue";

@Options<Home>({
  components: {
    SwipeIntroduce,
    ReactScrollImage,
    Footer
  },
  props:{
  }
})
export default class Home extends Vue {
  domEvenListenerManager = new DomEventListenerManager();
  isMobile = isMobile;
  screen = {
    height: 0,
    width: 0
  }

  sponsors = [
    require('../assets/images/sponsors/cocacola.png'),
    require('../assets/images/sponsors/aiqiyi.png'),
    require('../assets/images/sponsors/shangmi.png'),
    require('../assets/images/sponsors/keda.png'),
    require('../assets/images/sponsors/jianfa.png'),
    require('../assets/images/sponsors/nanjingSteel.png'),
    require('../assets/images/sponsors/fangda.png'),
    require('../assets/images/sponsors/zhonglun.png'),
    require('../assets/images/sponsors/Hong Kong land.png'),
    require('../assets/images/sponsors/wego.png'),
    require('../assets/images/sponsors/laohuzhengquan.png'),
    require('../assets/images/sponsors/shijijinyuan.png'),
  ];

  created(){
    this.screen =  screen;
  }
  mounted(){
    // const scrollParent = getScrollParentElement(this.$el);
    // this.domEvenListenerManager.registerListener(scrollParent,'scroll',() => {
    //   const scaleX = Math.min(1 + scrollParent.scrollTop * .00025,1.3);
    //   (this.$refs.mainBackgroundImage as HTMLElement).style.setProperty('transform',`scaleX(${scaleX})`);
    // })
  }
  unmounted(){
    this.domEvenListenerManager.removeListener();
  }
}
