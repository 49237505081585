import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-285b4240"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 1,
  class: "si-cross-background"
}
const _hoisted_2 = { class: "cross-frame" }
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  class: "si-left",
  ref: "siLeft"
}
const _hoisted_5 = {
  class: "si-features",
  ref: "siFeatures"
}
const _hoisted_6 = {
  class: "si-buttons",
  ref: "siButtons"
}
const _hoisted_7 = ["href"]
const _hoisted_8 = ["href"]
const _hoisted_9 = { class: "si-right" }
const _hoisted_10 = ["src", "onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ReactScrollImage = _resolveComponent("ReactScrollImage")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_Swiper = _resolveComponent("Swiper")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["swipe-introduce", {
          'cross-mode':_ctx.mode === 1
       }]),
    ref: "selfRef"
  }, [
    (_ctx.mode === 0)
      ? (_openBlock(), _createBlock(_component_ReactScrollImage, {
          key: 0,
          class: "si-background-image",
          src: _ctx.introduction.backgroundImage,
          width: _ctx.screen.width + 'px',
          widthOffset: _ctx.widthOffset
        }, null, 8, ["src", "width", "widthOffset"]))
      : (_ctx.mode === 1)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", {
                class: "cross-frame-left frame-part",
                style: _normalizeStyle({width:_ctx.crossLeftFrameWidthPercent + '%'} )
              }, [
                _createElementVNode("img", {
                  loading: "lazy",
                  class: "si-cross-background-image",
                  src: _ctx.introduction.backgroundImage
                }, null, 8, _hoisted_3)
              ], 4),
              _createElementVNode("div", {
                class: "cross-frame-right frame-part",
                style: _normalizeStyle({width: 100 - _ctx.crossLeftFrameWidthPercent + '%'} )
              }, null, 4)
            ])
          ]))
        : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h2", {
        class: "si-title",
        ref: "siTitle"
      }, _toDisplayString(_ctx.introduction.title), 513),
      _createElementVNode("h3", {
        class: "si-profile",
        ref: "siProfile"
      }, _toDisplayString(_ctx.introduction.profile), 513),
      _createElementVNode("ul", _hoisted_5, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.introduction.features, (text, index) => {
          return (_openBlock(), _createElementBlock("li", {
            class: "si-feature-item",
            key: index
          }, _toDisplayString(text), 1))
        }), 128))
      ], 512),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("a", {
          class: "si-btn",
          target: "_blank",
          href: _ctx.introduction.buttonLinks.experience
        }, "立即体验", 8, _hoisted_7),
        (_ctx.introduction.buttonLinks.videoIntroduce)
          ? (_openBlock(), _createElementBlock("a", {
              key: 0,
              class: "si-btn",
              target: "_blank",
              href: _ctx.introduction.buttonLinks.videoIntroduce
            }, "视频介绍", 8, _hoisted_8))
          : _createCommentVNode("", true)
      ], 512)
    ], 512),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_Swiper, {
        class: "si-images-swiper",
        autoplay: _ctx.swiperConfig.autoplay,
        effect: 'fade',
        fadeEffect: {
                crossFade:true
              },
        speed: _ctx.swiperConfig.speed,
        modules: _ctx.swiperConfig.modules,
        spaceBetween: _ctx.swiperConfig.spaceBetween,
        pagination: {
                type: 'fraction',
                horizontalClass: _ctx.swiperConfig.swiperPaginationClass
              }
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mapPreviewImageSet(_ctx.introduction.images), (imgUrl, index) => {
            return (_openBlock(), _createBlock(_component_swiper_slide, { key: index }, {
              default: _withCtx(() => [
                _createElementVNode("img", {
                  class: "si-image-item",
                  src: imgUrl,
                  onClick: ($event: any) => (_ctx.zoomOutImage($event,_ctx.mapReviewImageSet(_ctx.introduction.images),index))
                }, null, 8, _hoisted_10)
              ]),
              _: 2
            }, 1024))
          }), 128))
        ]),
        _: 1
      }, 8, ["autoplay", "speed", "modules", "spaceBetween", "pagination"])
    ])
  ], 2))
}