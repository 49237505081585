import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navigator = _resolveComponent("Navigator")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_FullScreenImageSwiper = _resolveComponent("FullScreenImageSwiper")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Navigator),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({ Component }) => [
        _createVNode(_Transition, {
          onBeforeEnter: _ctx.beforeEnter,
          onEnter: _ctx.enter,
          onLeave: _ctx.leave,
          mode: "out-in",
          class: "meta-page",
          css: false
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component), { ref: "page" }, null, 512))
          ]),
          _: 2
        }, 1032, ["onBeforeEnter", "onEnter", "onLeave"])
      ]),
      _: 1
    }),
    _withDirectives(_createVNode(_component_FullScreenImageSwiper, {
      style: {"z-index":"2","background-color":"rgba(0,0,0,.8)"},
      images: _ctx.images,
      onClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.fullScreenVisible = false)),
      "current-index": _ctx.currentFullScreenImageIndex
    }, null, 8, ["images", "current-index"]), [
      [_vShow, _ctx.fullScreenVisible]
    ])
  ], 64))
}