
import anime from "animejs";
import { Options, Vue } from 'vue-class-component';
import Navigator from "@/components/Navigator.vue";
import FullScreenImageSwiper from "@/components/FullScreenImageSwiper.vue";

@Options<App>({
  components:{
    Navigator,
    FullScreenImageSwiper
  },
  provide(){
    return {
      openFullScreenImageSwiper: this.openSwiper
    }
  }
})
export default class App extends Vue {
  images?:string[];
  currentFullScreenImageIndex = 0;
  fullScreenVisible = false;
  appRef = document.querySelector('#app');

  openSwiper(images:string[],index:number){
    this.images = images;
    this.currentFullScreenImageIndex = index;
    this.fullScreenVisible =  true;
  }

  beforeEnter(el:HTMLElement){
    anime.set(el,{
      opacity:0,
    })
  }

  enter(el:HTMLElement,done:() => any){
    anime({
      targets:el,
      opacity:1,
      duration:300,
      easing:'linear',
      complete:function (){
        done();
      }
    })
  }

  leave(el:HTMLElement,done:() => any){
    anime({
      targets:el,
      duration:200,
      easing:'easeInCubic',
      opacity:0,
      complete: () =>{
        done();
        this.appRef!.scrollTop = 0;
      }
    })
  }


}

