
import {Options, Vue} from 'vue-class-component';
import {Swiper,SwiperSlide} from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
import ReactScrollImage from "@/components/ReactScrollImage.vue";
import SwipeIntroduceData from "@/types/SwipeIntroduceData";
import {PropType} from "vue";
import { DomEventListenerManager, getScrollParentElement } from '@/utils.js';
import { Pagination, Autoplay, EffectFade } from "swiper";
import anime from "animejs";

@Options<SwipeIntroduce>({
  components: {
    Swiper,
    SwiperSlide,
    ReactScrollImage
  },
  props: {
    introduction:{
      required: true,
      type: Object as PropType<SwipeIntroduceData>
    },
    mode:{
      required:false,
      type: Number as PropType<0 | 1>,
      default: 0,
    },
    crossLeftFrameWidthPercent: {
      required:false,
      type: Number as PropType<number>,
      default:50,
    }
  },
  inject:['openFullScreenImageSwiper']
})
export default class SwipeIntroduce extends Vue {
  openFullScreenImageSwiper?: (images:string[],index:number) => void;
  introduction?:SwipeIntroduceData;
  mode?: 0 | 1;
  crossLeftFrameWidthPercent?: number;

  swiperConfig = {
    modules: [Pagination, Autoplay, EffectFade],
    speed:1800,
    spaceBetween:0,
    swiperPaginationClass: 'meta-swiper-pagination',
    autoplay:{
      delay:1500,
      pauseOnMouseEnter: false,
      disableOnInteraction: false,
    }
  }

  screen = {
    width: 0,
    height: 0,
  };

  widthOffset = 200;

  domEventListenerManager = new DomEventListenerManager();

  observer?: IntersectionObserver;

  zoomOutImage(event:MouseEvent,images:string[],index:number){
    this.openFullScreenImageSwiper?.(images,index);
    // (event.currentTarget! as HTMLElement).requestFullscreen();
  }

  created(){
    this.screen = screen;
  }

  mapPreviewImageSet(data: (string | {preview: string, review: string})[]) {
    return data.map((el) => {
      if(typeof el === 'string') {
        return el
      }
      return el.preview;
    })
  }

  mapReviewImageSet(data: (string | {preview: string, review: string})[] ) {
    return data.map((el) => {
      if(typeof el === 'string') {
       return el
      }
      return el.review;
    })
  }

  mounted(){
    const targets = [this.$refs.siTitle,this.$refs.siProfile,[...(this.$refs.siFeatures as HTMLElement).children],this.$refs.siButtons];
    anime.set(targets,{
      opacity:0
    });
    this.observer = new IntersectionObserver(([entry],observer) => {
      if(entry.intersectionRatio >= .6){
        anime({
          targets,
          duration:800,
          opacity:1,
          easing:'easeOutCubic',
          translateX:[-100,0],
          delay:function (el,i){
            return i * 100;
          }
        })
        observer.disconnect();
      }
    },{
      root:null,
      rootMargin:'0px',
      threshold:.7
    });
    this.observer.observe(this.$refs.siLeft as HTMLElement);
  }

  unmounted(){
    this.observer?.disconnect();
    this.domEventListenerManager.removeListener();
  }

}
