
import {Options, Vue} from 'vue-class-component';
// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";
// import required modules
import { Navigation } from "swiper";
import {PropType} from "vue";
import SvgIcon from "@/components/SvgIcon.vue";

@Options<FullScreenImageSwiper>({
  components: {
    Swiper,
    SwiperSlide,
    SvgIcon
  },
  props: {
    images:Array as PropType<string[]>,
    currentIndex:Number,
  }
})
export default class FullScreenImageSwiper extends Vue {
  images?:string[];
  currentIndex?:number;
  modules = [Navigation];
  swiper:any = null;

  onSwiper(swiper:any){
    this.swiper = swiper
  }

  updated(){
    this.swiper.slideTo(this.currentIndex);
  }

}
