
import {Options, Vue} from 'vue-class-component';
import { createPopper } from "@popperjs/core";

@Options<Footer>({
  components: {},
  props: {}
})
export default class Footer extends Vue {
  platforms = {
    weixin: require('@/assets/images/platforms/微信.png'),
    weibo: require('@/assets/images/platforms/微博.png'),
    toutiao: require('@/assets/images/platforms/今日头条.jpg'),
    xiaohongshu: require('@/assets/images/platforms/xiaohongshu.png')
  };

  mounted(){
    type placement = 'top';
    const popperOptions = {
      placement:'top' as placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, 10],
          },
        },
        {
          name: 'arrow',
          options:{
            padding: 5
          }
        },
      ],
    };
    createPopper((this.$refs.weixin as HTMLElement),(this.$refs.weixinPopper as HTMLElement),popperOptions);
    createPopper((this.$refs.weibo as HTMLElement),(this.$refs.weiboPopper as HTMLElement),popperOptions);
    createPopper((this.$refs.toutiao as HTMLElement),(this.$refs.toutiaoPopper as HTMLElement),popperOptions);
    createPopper((this.$refs.xiaohongshu as HTMLElement),(this.$refs.xiaohongshuPopper as HTMLElement),popperOptions);
  }
}
