
import {Options, Vue} from 'vue-class-component';
import {DomEventListenerManager, getScrollParentElement} from "@/utils";
import {PropType} from "vue";
import anime from "animejs";

@Options<ReactScrollImage>({
  components: {},
  props: {
    src:{
      required:true,
      type: String
    },
    width:{
      required:false,
      default:'100%'
    },
    height:{
      required:false,
      default: 'auto'
    },
    widthOffset:{
      required:false,
      default: 0,
    },
    mode:{
      required:false,
      type: String as PropType<'translateX' | 'scaleX' | 'scale'>,
      default: 'translateX'
    },
    dev:{
      required:false,
      type: Boolean,
      default: false
    }
  }
})
export default class ReactScrollImage extends Vue {
  src?: string;
  width?: string;
  height?: string;
  widthOffset?: number;
  mode?: 'translateX' | 'scaleX';

  dev?:boolean;
  startScrollTop = 0;
  observer?:IntersectionObserver;
  scrollParent?: HTMLElement;

  domEventListenerManager = new DomEventListenerManager();
  updateOffset(){
    if(!this.scrollParent) return;
    if(this.mode === 'scaleX'){
      const scaleX = Math.min(1 + (this.scrollParent.scrollTop - this.startScrollTop) * .00025,1.3);
      if(this.dev) console.log('scaleX',scaleX,this.$el);
      (this.$refs.image as HTMLElement).style.setProperty('transform',`scaleX(${scaleX})`);
    }else if(this.mode === 'translateX'){

      const translateX = this.widthOffset! * (this.scrollParent.scrollTop - this.startScrollTop) / (1.5 * this.scrollParent.clientHeight);
      if(this.dev){
        console.log('translateX',translateX,this.$el)
      }
      (this.$refs.image as HTMLElement).style.setProperty('transform',`translateX(-${translateX}px)`);
    }else if(this.mode === 'scale'){
      const scale = Math.min(1 + (this.scrollParent.scrollTop - this.startScrollTop) * .00025,1.3);
      if(this.dev) console.log('scale',scale,this.$el);
      (this.$refs.image as HTMLElement).style.setProperty('transform',`scale(${scale})`);
    }
  }

  mounted(){
    this.scrollParent = getScrollParentElement(this.$el as HTMLElement);
    const elTop = Math.round(this.$el.getBoundingClientRect().top - parseInt(anime.get(this.$el,'margin-top') as string));
    if(this.$el.offsetParent.contains(this.scrollParent) && this.$el.offsetParent !== this.scrollParent){
      this.startScrollTop = elTop;
    }else{
      this.startScrollTop = Math.max(this.scrollParent.scrollTop + elTop - this.scrollParent.getBoundingClientRect().top - this.scrollParent.clientHeight,0);
    }

    if(this.dev){
      // eslint-disable-next-line no-debugger
      // debugger
      console.log('startScrollTop',this.startScrollTop);
    }
    if(this.scrollParent){
      this.updateOffset();
      let pending = false;
      this.domEventListenerManager.registerListener(this.scrollParent,'scroll',() => {
        if(pending) return;
        pending = true;
        requestAnimationFrame(() => {
          pending = false;
          this.updateOffset();
        })

      })
    }
  }

  unmounted(){
    this.observer?.disconnect();
    this.domEventListenerManager.removeListener();
  }
}
